<template>
  <UserProfile
      @deleted="handleDelete"
      :propped-user="user"
      :tabs="[
      { title: 'User Details', role: 'details' },
      { title: 'Profile', role: 'profile' },
      { title: 'Wards', role: 'wards' },
    ]"
      :profile-fields="[
      'id',
      'gender',
      'ethnicity',
      'home_address',
      'work_address',
      'occupation'
    ]"
      v-if="selected"
      :type="'Guardian'"
      :canCreateEnrolment="
      !!$store.getters['entities/user-permissions/find']('create enrolments')
    "
      :canDeleteEnrolment="
      !!$store.getters['entities/user-permissions/find']('delete enrolments')
    "
      :canEditEnrolment="
      !!$store.getters['entities/user-permissions/find']('edit enrolments')
    "
      :canCreateSubject="
      !!$store.getters['entities/user-permissions/find']('create subjects')
    "
      :canDeleteSubject="
      !!$store.getters['entities/user-permissions/find']('delete subjects')
    "
      :canEditSubject="
      !!$store.getters['entities/user-permissions/find']('edit subjects')
    "
      :canCreateExtramural="
      !!$store.getters['entities/user-permissions/find']('create extramurals')
    "
      :canDeleteExtramural="
      !!$store.getters['entities/user-permissions/find']('delete extramurals')
    "
      :canEditExtramural="
      !!$store.getters['entities/user-permissions/find']('edit extramurals')
    "
      :canCreate="
      !!$store.getters['entities/user-permissions/find']('create guardians')
    "
      :canDelete="
      !!$store.getters['entities/user-permissions/find']('delete guardians')
    "
      :canEdit="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canCreateGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canDeleteGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canEditGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canCreateWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canDeleteWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canEditWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :can-create-learner="
      !!$store.getters['entities/user-permissions/find']('create learners')
    "
  ></UserProfile>
</template>

<script>
import UserProfile from "@/components/users/UserProfile";
import User from "@/models/User";

export default {
  props: ["id"],
  components: {
    UserProfile,
  },
  data() {
    return {
      selected: false,
      user: null,
    };
  },

  methods: {
    handleDelete() {
      this.$router.push("/guardians");
    },
  },
  mounted() {
    User.FetchById(this.id).then(({ entities }) => {
      this.user = entities.users[0];
      this.selected = true;
    });
  },
};
</script>
